@property --rotate {
  syntax: '<angle>';
  initial-value: 132deg;
  inherits: false;
}

:root {
  --rotate: 0deg;
}

.glow-border {
  position: relative;
}

.glow-border:before,
.glow-border:after {
  position: absolute;
  background: linear-gradient(var(--rotate), #0ea5e9, #10b981);
}

.glow-border--animated:before,
.glow-border--animated:after {
  animation: border-spin 2.5s linear infinite;
}

.glow-border:after {
  content: '';
  inset: -8px -8px;
  z-index: -1;
  border-radius: 70px;
}

.glow-border:before {
  content: '';
  left: 0;
  top: 10%;
  width: 100%;
  height: 100%;
  z-index: -2;
  filter: blur(5rem) opacity(0.6);
  transform: scale(0.9);
}

@keyframes border-spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}
