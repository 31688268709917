.overflow,
.inner-shadow {
  display: none !important;
}

.marvel-device.iphone-x .camera,
.marvel-device.iphone-x .top-bar,
.marvel-device.iphone-x .bottom-bar {
  display: none;
}

.screen {
  background: none !important;
}

.marvel-device.iphone-x {
  padding: 10px;
}
.marvel-device.iphone-x .screen {
  border-radius: 60px;
}

.marvel-device.iphone-x .notch {
  display: none;
  // width: 100px;
  // left: 0;
  // right: 0;
  // margin-left: auto;
  // margin-right: auto;
  // top: 5px;
}
